import type { DbUserAccount } from '~/types/account.types'

const selectedUserAccountIds = ref<DbUserAccount['id'][]>([])

export function useUserAccountSelection() {
  // Loading the saved value from cookie (and updating it when necessary)
  useCookieSaving<DbUserAccount['id'][]>('selectedUserAccountIds', selectedUserAccountIds)

  return {
    selectedUserAccountIds,
  }
}